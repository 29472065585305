.navigo-module-dashboard-table-container {
  .navigo-dashboard-container {
          font-family: "Montserrat" !important;
  
          position: relative;
  
          .navigo-dashboard-container-layout-1 {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              margin: 180px auto 0 auto;
  
              .navigo-layout-1-main-heading {
                  display: block;
                  font-size: 48px;
                  font-weight: 700;
              }
  
              .navigo-layout-1-main-desc {
                  font-size: 16px;
                  font-weight: 500;
                  margin: 20px 0;
                  color: rgba(51, 51, 51, 0.6);
              }
  
              .navigo-layout-1-services-options {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-wrap: wrap;
                  row-gap: 16px;
                  column-gap: 16px;
  
                  .navigo-layout-1-services-option {
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      border: 1px solid #dee2e6;
                      padding: 40px 24px;
                      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.06),
                          0px 1px 3px rgba(16, 24, 40, 0.1);
                      border-radius: 12px;
                      text-align: center;
                      max-width: 260px;
                      column-gap: 16px;
                      row-gap: 16px;
                      cursor: pointer;
  
                      img {
                          width: 24px;
                          height: 24px;
                      }
  
                      .navigo-layout-1-services-option-heading {
                          font-size: 20px;
                          font-weight: 800;
                          color: #333;
                      }
  
                      .navigo-layout-1-services-option-desc {
                          color: rgba(51, 51, 51, 0.6);
                          font-size: 16px;
                          font-weight: 500;
                      }
                  }
              }
          }
      }
  
      .navigo-appoint-container {
          width: 100%;
          height: 100%;
          padding-left: 15px;
  
          .navigo-appoint-section-3 {
              display: flex;
              align-items: center;
              padding: 12px 12px 0 0;
              justify-content: flex-start;
  
              .navigo-appoint-section-1 {
                  cursor: pointer;
  
                  i {
                      margin-right: 12px;
                      color: #ef7c01;
                  }
              }
  
              .navigo-appoint-section-2 {
                  font-size: 21px;
                  line-height: 21px;
                  font-weight: 700;
                  color: #333;
                  margin: 0 12px 0 0;
              }
  
              .navigo-appoint-section-3-left {
                  margin-right: 12px;
  
                  .navigo-appoint-section-3-left-button {
                      outline: none;
                      border: none;
                      background-color: transparent;
                      font-size: 16px;
                      font-weight: 500;
                      color: #777;
                      padding-left: 0;
                      padding-right: 8px;
                      cursor: pointer;
                  }
  
                  .right-border-class {
                      border-right: 1px solid #777;
                  }
  
                  .left-padding-class {
                      padding-left: 8px;
                  }
  
                  .selected-button {
                      color: #00c9d3;
                      font-weight: 600;
                  }
              }
  
              .navigo-appoint-section-4 {
                  display: flex;
                  flex-direction: column;
  
                  margin-right: 12px;
                  flex: 1;
                  max-width: 600px;
  
                  .navigo-appoint-section-4-top {
                      font-size: 14px;
                      font-weight: 400;
                      color: #00c9d3;
                      margin-bottom: 0px;
                  }
  
                  .navigo-appoint-section-4-input-container {
                      display: flex;
                      align-items: center;
                      gap: 10px;
                  }
  
                  .navigo-appoint-section-4-select {
                      width: 120px;
                      margin-right: 10px;
                  }
  
                  .navigo-appoint-section-4-autocomplete-input input[type='number']::-webkit-inner-spin-button,
                  .navigo-appoint-section-4-autocomplete-input input[type='number']::-webkit-outer-spin-button {
                      -webkit-appearance: none;
                      margin: 0;
                  }
  
                  .navigo-appoint-section-4-bottom {
                      display: flex;
                      align-items: center;
                      gap: 30px;
  
                      .navigo-appoint-section-4-autocomplete-input {
                          display: flex;
                          width: 200px;
  
                          .MuiInput-underline:before {
                              border-bottom: none;
                              width: 200px;
                          }
  
                          .MuiInput-underline:after {
                              border-bottom: none;
                              width: 200px;
                          }
  
                          input {
                              padding: 0;
                              width: auto;
                              display: flex;
                          }
                      }
  
                      .navigo-appoint-section-4-bottom-icon {
                          color: #c2c2c2;
                          padding-bottom: 4px;
                          margin-right: 8px;
                      }
                  }
  
                  .navigo-appoint-section-4-bottom-mid {
                      flex: 1;
                      margin-right: 12px;
                      display: flex;
                      border-bottom: 0.5px solid rgba(70, 70, 70, 0.2);
                  }
              }
  
              .navigo-appoint-section-4-bottom-button-container {
                  margin-left: auto;
                  display: block;
              }
  
              .navigo-appoint-section-4-bottom-button {
                  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.122339);
                  background-color: #ef7c01;
                  outline: none;
                  border: none;
                  border-radius: 4px;
                  color: white;
                  font-size: 16px;
                  font-weight: 400;
                  padding: 5px 12px;
                  margin-right: 4px;
                  cursor: pointer;
  
                  &:disabled {
                      background-color: #c2c2c2 !important;
                  }
              }
          }
  
  
          .navigo-appoint-section-4-field {
              display: flex;
              align-items: center;
              gap: 10px;
          }
  
          .navigo-appoint-section-4-textfield {
              width: 150px;
          }
  
          .navigo-phone-number-field {
              display: flex;
              // gap: 10px; 
          }
  
          .navigo-country-code-select {
              width: 50px;
          }
  
          .navigo-phone-number-input {
              width: 150px;
          }
  
          .navigo-appoint-section-5 {
              position: sticky;
              top: 64px;
              background-color: white;
              z-index: 100;
  
              .navigo-appoint-section-5-grid-container {
                  display: grid;
                  grid-template-columns: 7.6% 7.6% 7.6% 7.6% 7.6% 7.6% 7.6% 4.6% 7.6% 7.6% 10.6% 7.6% 7.6%;
                  box-shadow: 0px 2px 2px rgba(147, 147, 147, 0.5);
  
                  border-radius: 2px;
                  padding: 8px 8px;
                  margin-top: 14px;
                  justify-items: center;
  
                  .navigo-appoint-section-5-grid-container-common-section {
                      p {
                          font-size: 11px;
                          color: #464646;
                          font-weight: 600;
                          display: flex;
                          align-items: center;
                          font-family: Montserrat;
                          margin: 0;
                          text-align: center;
                      }
  
                      display: flex;
                      position: relative;
                      align-items: center;
  
                      img {
                          cursor: pointer;
                          margin-left: 4px;
                          width: 10px;
                          height: 10px;
                          position: relative;
                          // z-index: 50;
                      }
  
                      .navigo-appoint-section-5-grid-container-section-common-filter-popup {
                          position: absolute;
                          bottom: -76px;
                          right: -88%;
                          transform: translateX(20%);
                          z-index: 50;
                          background-color: white;
                          width: 400px;
                          padding: 20px;
                          border-radius: 8px;
                          border: 1px solid #d8d8d8;
                          display: flex;
                          align-items: flex-end;
  
                          .appointment-dialog-client-code-filter-class {
                              flex: 1;
                          }
  
                          // height: 100px;
                          .navigo-appointment-date-range-filter {
                              flex: 1;
                          }
                      }
                      .navigo-appoint-section-5-grid-container-section-common-filter-popup-status {
                        position: absolute;
                        bottom: -76px;
                        right: 81%;
                        transform: translateX(20%);
                        z-index: 50;
                        background-color: white;
                        width: 300px;
                        padding: 20px;
                        border-radius: 8px;
                        border: 1px solid #d8d8d8;
                        display: flex;
                        align-items: flex-end;

                        .appointment-dialog-client-code-filter-class {
                            flex: 1;
                        }

                        // height: 100px;
                        .navigo-appointment-date-range-filter {
                            flex: 1;
                        }
                    }
                      .navigo-appoint-section-5-grid-container-section-common-filter-popup-city {
                        position: absolute;
                        bottom: -76px;
                        right: -13%;
                        transform: translateX(20%);
                        z-index: 50;
                        background-color: white;
                        width: 200px;
                        padding: 20px;
                        border-radius: 8px;
                        border: 1px solid #d8d8d8;
                        display: flex;
                        align-items: flex-end;

                        .appointment-dialog-client-code-filter-class {
                            flex: 1;
                        }

                        // height: 100px;
                        .navigo-appointment-date-range-filter {
                            flex: 1;
                        }
                    }
                      .navigo-appoint-section-5-grid-container-section-common-filter-popup-acccountid {
                        position: absolute;
                        bottom: -76px;
                        right: -88%;
                        transform: translateX(20%);
                        z-index: 50;
                        background-color: white;
                        width: 200px;
                        padding: 20px;
                        border-radius: 8px;
                        border: 1px solid #d8d8d8;
                        display: flex;
                        align-items: flex-end;

                        .appointment-dialog-client-code-filter-class {
                            flex: 1;
                        }

                        // height: 100px;
                        .navigo-appointment-date-range-filter {
                            flex: 1;
                        }
                    }
                    .navigo-appoint-section-5-grid-container-section-common-filter-popup-acccount-name {
                        position: absolute;
                        bottom: -76px;
                        right: -88%;
                        transform: translateX(20%);
                        z-index: 50;
                        background-color: white;
                        width: 200px;
                        padding: 20px;
                        border-radius: 8px;
                        border: 1px solid #d8d8d8;
                        display: flex;
                        align-items: flex-end;

                        .appointment-dialog-client-code-filter-class {
                            flex: 1;
                        }

                        // height: 100px;
                        .navigo-appointment-date-range-filter {
                            flex: 1;
                        }
                    }
                  }
              }
  
              .navigo-appoint-section-5-grid-container-claim {
                  display: grid;
                  grid-template-columns: 11% 11% 11% 11%;
                  box-shadow: 0px 2px 2px rgba(147, 147, 147, 0.5);
  
                  border-radius: 2px;
                  padding: 8px 8px;
                  margin-top: 14px;
                  justify-items: center;
  
                  .navigo-appoint-section-5-grid-container-common-section {
                      p {
                          font-size: 11px;
                          color: #464646;
                          font-weight: 600;
                          display: flex;
                          align-items: center;
                          font-family: Montserrat;
                          margin: 0;
                          text-align: center;
                      }
  
                      display: flex;
                      position: relative;
                      align-items: center;
  
                      img {
                          cursor: pointer;
                          margin-left: 4px;
                          width: 10px;
                          height: 10px;
                          position: relative;
                          // z-index: 50;
                      }
  
                      .navigo-appoint-section-5-grid-container-section-common-filter-popup {
                          position: absolute;
                          bottom: -90px;
                          right: 0%;
                          transform: translateX(20%);
                          z-index: 50;
                          background-color: white;
                          width: 400px;
                          padding: 20px;
                          border-radius: 8px;
                          border: 1px solid #d8d8d8;
                          display: flex;
                          align-items: flex-end;
  
                          .appointment-dialog-client-code-filter-class {
                              flex: 1;
                          }
  
                          // height: 100px;
                          .navigo-appointment-date-range-filter {
                              flex: 1;
                          }
                      }
                  }
              }
          }
  
          .navigo-appoint-section-6 {
              .navigo-appoint-bottom-bar {
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  margin: 6px 0;
  
                  .navigo-count-icon {
                      font-size: 30px;
                      width: fit-content;
                      transform: rotate(-90deg);
                  }
  
                  .navigo-appoint-bottom-bar-button {
                      display: inline-flex;
                      align-items: center;
                      justify-content: center;
                      border-radius: 100%;
                      width: 30px;
                      height: 30px;
  
                      .navigo-count-icon {
                          color: #ef7c01;
                      }
                  }
  
                  .navigo-appoint-bottom-bar-button:disabled {
                      .navigo-count-icon {
                          color: gray !important;
                      }
                  }
  
                  .navigo-appoint-bottom-bar-text {
                      padding-left: 5px;
                      padding-right: 5px;
                  }
              }
  
              .navigo-appoint-section-6-list {
                  // margin-top: 12px;
                  box-shadow: 0px 2px 4px rgba(147, 147, 147, 0.5);
  
                  .navigo-appoint-section-6-grid-container {
                      display: grid;
                      grid-template-columns: 7.6% 7.6% 7.6% 7.6% 7.6% 7.6% 7.6% 4.6% 7.6% 7.6% 10.6% 7.6% 7.6% 1%;
                      border-radius: 2px;
                      padding: 5px 8px;
                      border-bottom: 1px solid #d8d8d8;
                      justify-items: center;
  
                      .general-font-class {
                          color: #464646;
                          font-size: 13px;
                          line-height: 24px;
                          font-weight: 500;
                          text-overflow: ellipsis;
                          word-break: break-all;
                          text-align: center;
                          display: -webkit-box;
                          -webkit-line-clamp: 1;
                          -webkit-box-orient: vertical;
                          overflow: hidden;
  
                          // margin: 0 12px;
                      }
  
                      .navigo-appoint-section-6-grid-status {
                          .MuiTextField-root {
                              .MuiInput-underline::before {
                                  border-bottom: none !important;
                                  content: "";
                              }
  
                              .MuiInput-underline::after {
                                  border-bottom: none !important;
                                  content: "";
                              }
                          }
  
                          .navigo-status-selector {
                              border-radius: 69px;
                              padding: 0 10px;
                              height: fit-content;
                              font-size: 14px;
                              font-weight: 700;
  
                              .MuiSelect-select.MuiSelect-select {
                                  background-color: transparent;
                                  padding-top: 2px;
                                  padding-bottom: 2px;
                                  padding-right: 12px !important;
                                  font-family: "Montserrat" !important;
                              }
                          }
  
                          .navigo-status-selector.MuiInput-underline::before {
                              content: "";
  
                              border-bottom: none;
                          }
  
                          .navigo-status-selector.MuiInput-underline::after {
                              content: "";
                              border-bottom: none !important;
                          }
  
                          .navigo-status-selector.Mui-focused {
                              border-bottom: none;
                          }
  
                          select {
                              border-radius: 25px;
                              border: none;
                          }
  
                          .pending-class {
                              background-color: #fff9e3;
                              color: #ffbd2d;
  
                              .MuiSvgIcon-root {
                                  color: #ffbd2d;
                              }
                          }
  
                          .progress-class {
                              background-color: rgba(239, 124, 1, 0.1);
                              color: #ef7c01;
  
                              .MuiSvgIcon-root {
                                  color: #ef7c01;
                              }
                          }
  
                          .closed-class {
                              background-color: rgba(48, 197, 63, 0.1);
                              color: rgba(48, 197, 63, 1);
  
                              .MuiSvgIcon-root {
                                  color: rgba(48, 197, 63, 1);
                              }
                          }
  
                          .invalid-class {
                              background: rgba(0, 0, 0, 0.1);
  
                              color: black;
  
                              .MuiSvgIcon-root {
                                  color: black;
                              }
                          }
  
                          .navigo-appoint-non-select-status-inactive {
                              background: #FBDFD9;
                              color: #E44437;
                              border-radius: 60px;
                              padding: 2px 10px;
                              width: fit-content;
                              font-size: 14px;
                              font-weight: 700;
                          }
  
                          .navigo-appoint-non-select-status-active {
                              background-color: #caecde;
                              color: #0b5033;
                              border-radius: 60px;
                              padding: 2px 10px;
                              width: fit-content;
                              font-size: 14px;
                              font-weight: 700;
                          }
                      }
  
                      .navigo-appoint-section-6-grid-edit-call {
                          margin-left: auto;
                          margin-right: 4px;
                          padding: 0px;
  
                          &:hover {
                              background-color: transparent;
                          }
                      }
                  }
  
                  .navigo-appoint-section-6-grid-container-claim {
                      display: grid;
                      grid-template-columns: 7% 6% 8% 8% 6% 6% 7% 7% 7% 7% 7% 7% 8% 8% 2%;
                      border-radius: 2px;
                      padding: 5px 8px;
                      border-bottom: 1px solid #d8d8d8;
                      justify-items: center;
  
                      .general-font-class {
                          color: #464646;
                          font-size: 13px;
                          line-height: 24px;
                          font-weight: 500;
                          text-overflow: ellipsis;
                          word-break: break-all;
                          text-align: center;
                          display: -webkit-box;
                          -webkit-line-clamp: 1;
                          -webkit-box-orient: vertical;
                          overflow: hidden;
  
                          // margin: 0 12px;
                      }
  
                      .navigo-appoint-section-6-grid-status {
                          .MuiTextField-root {
                              .MuiInput-underline::before {
                                  border-bottom: none !important;
                                  content: "";
                              }
  
                              .MuiInput-underline::after {
                                  border-bottom: none !important;
                                  content: "";
                              }
                          }
  
                          .navigo-status-selector {
                              border-radius: 69px;
                              padding: 0 10px;
                              height: fit-content;
                              font-size: 14px;
                              font-weight: 700;
  
                              .MuiSelect-select.MuiSelect-select {
                                  background-color: transparent;
                                  padding-top: 2px;
                                  padding-bottom: 2px;
                                  padding-right: 12px !important;
                                  font-family: "Montserrat" !important;
                              }
                          }
  
                          .navigo-status-selector.MuiInput-underline::before {
                              content: "";
  
                              border-bottom: none;
                          }
  
                          .navigo-status-selector.MuiInput-underline::after {
                              content: "";
                              border-bottom: none !important;
                          }
  
                          .navigo-status-selector.Mui-focused {
                              border-bottom: none;
                          }
  
                          select {
                              border-radius: 25px;
                              border: none;
                          }
  
                          .pending-class {
                              background-color: #fff9e3;
                              color: #ffbd2d;
  
                              .MuiSvgIcon-root {
                                  color: #ffbd2d;
                              }
                          }
  
                          .progress-class {
                              background-color: rgba(239, 124, 1, 0.1);
                              color: #ef7c01;
  
                              .MuiSvgIcon-root {
                                  color: #ef7c01;
                              }
                          }
  
                          .closed-class {
                              background-color: rgba(48, 197, 63, 0.1);
                              color: rgba(48, 197, 63, 1);
  
                              .MuiSvgIcon-root {
                                  color: rgba(48, 197, 63, 1);
                              }
                          }
  
                          .invalid-class {
                              background: rgba(0, 0, 0, 0.1);
  
                              color: black;
  
                              .MuiSvgIcon-root {
                                  color: black;
                              }
                          }
  
                          .navigo-appoint-non-select-status-invalid {
                              background: rgba(0, 0, 0, 0.1);
                              color: black;
                              border-radius: 60px;
                              padding: 2px 10px;
                              width: fit-content;
                              font-size: 14px;
                              font-weight: 700;
                          }
  
                          .navigo-appoint-non-select-status-closed {
                              background-color: #caecde;
                              color: #0b5033;
                              border-radius: 60px;
                              padding: 2px 10px;
                              width: fit-content;
                              font-size: 14px;
                              font-weight: 700;
                          }
                      }
  
                      .navigo-appoint-section-6-grid-edit-call {
                          margin-left: auto;
                          margin-right: 4px;
                          padding: 0px;
  
                          &:hover {
                              background-color: transparent;
                          }
                      }
                  }
  
                  .navigo-appoint-section-6-grid-container:hover {
                      z-index: 2;
                      position: relative;
                      box-shadow: 4px 3px 10px #d8d8d8;
  
                      // padding: 12px 8px !important;
                      .general-font-class {
                          display: -webkit-box;
                          -webkit-line-clamp: 4 !important;
                          -webkit-box-orient: vertical;
                          overflow: hidden;
                      }
                  }
  
                  .navigo-appoint-section-6-grid-container-claim:hover {
                      z-index: 2;
                      position: relative;
                      box-shadow: 4px 3px 10px #d8d8d8;
  
                      // padding: 12px 8px !important;
                      .general-font-class {
                          display: -webkit-box;
                          -webkit-line-clamp: 4 !important;
                          -webkit-box-orient: vertical;
                          overflow: hidden;
                      }
                  }
              }
  
              .no-appointments-container {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-top: 48px;
  
                  p {
                      font-size: 48px;
                      font-weight: 700;
                      color: #333;
                  }
              }
          }
  
          .navigo-dialog-container {
              position: fixed;
              top: 0;
              left: 0;
              background-color: rgba(0, 0, 0, 0.4);
              width: 100vw;
              height: 100vh;
              z-index: 100;
              display: flex;
              align-items: center;
              justify-content: center;
          }
  
          .navigo-appointment-backdrop-class {
              position: fixed;
              top: 0;
              left: 0;
              background-color: transparent;
              z-index: 45;
              width: 100vw;
              height: 100vh;
          }
  
          .navigo-appoint-upload-csv-dialog-container {
              background-color: white;
              border-radius: 16px;
              padding: 24px;
              display: flex;
              flex-direction: column;
              max-height: 600px;
  
              overflow-y: scroll;
  
              img {
                  width: 200px;
                  height: 192px;
                  display: block;
                  margin: 16px auto;
              }
  
              p {
                  text-align: left;
              }
          }
  
          .navigo-appoint-upload-dialog-loading {
              position: absolute;
              top: 50%;
              left: 50%;
              background-color: rgba(255, 255, 255, 0.8);
              padding: 20px;
              border-radius: 8px;
          }
  
          .navigo-appoint-upload-dialog-loading p {
              margin: 0;
              font-size: 16px;
              font-weight: bold;
          }
      }
  
      .appointment-dialog-container {
          background-color: white;
          padding: 32px 24px;
          max-width: 700px;
          min-width: 700px;
          transition: all 1s ease-out;
          border-radius: 12px;
  
          .appointment-dialog-container-main {
              .appointment-dialog-main-top-heading {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-bottom: 20px;
  
                  p {
                      font-weight: 800;
                      font-size: 20px;
                      color: #333;
                  }
  
                  .appointment-dialog-main-close {
                      cursor: pointer;
                  }
              }
  
              .appointment-dialog-main-section-1 {
                  .appointment-dialog-main-section-1-name {}
  
                  .MuiInput-underline:before {
                      border-bottom: none !important;
                  }
  
                  .MuiInput-underline::after {
                      border-bottom: none !important;
                  }
  
                  .appointment-dialog-main-section-1-address {
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
  
                      a {
                          text-decoration: none;
                          outline: none;
                          border: none;
                          background-color: white;
                          color: #f97f0f;
                          font-size: 14px;
                          font-weight: 600;
                      }
                  }
              }
  
              .common-section-input-class {
                  border-bottom: 1px solid #d8d8d8;
                  margin-bottom: 12px;
  
                  p {
                      font-size: 12px;
                      font-weight: 400;
                      color: #777;
                      margin-bottom: 0;
                  }
  
                  input {
                      width: 100%;
                      outline: none;
                      border: none;
                      font-size: 14px;
                      font-weight: 400;
                      color: #464646;
                      padding: 6px 0;
                  }
              }
  
              .appointment-dialog-main-section-vendor {
                  margin-bottom: 12px;
  
                  p {
                      font-size: 12px;
                      font-weight: 400;
                      color: #777;
                      margin-bottom: 0px;
                  }
  
                  .appointment-dialog-section-2-select {
                      width: 100%;
                  }
  
                  .MuiInput-underline:before {
                      border-bottom: 1px solid #d8d8d8 !important;
                  }
              }
  
              .appointment-dialog-main-section-5-edit-heading {
                  font-size: 10px;
                  font-weight: 800;
                  color: #000;
                  margin: 12px 0;
              }
  
              .appointment-dialog-main-section-5 {
                  display: grid;
                  column-gap: 20px;
                  grid-template-columns: 1fr 1fr;
  
                  .appointment-dialog-main-section-5-left {
                      display: flex;
                      flex-direction: column;
                      border-bottom: 1px solid #d8d8d8;
  
                      i {
                          width: 22px;
                          height: 22px;
                          color: #d8d8d8;
                          margin-right: 8px;
                      }
  
                      p {
                          font-size: 12px;
                          font-weight: 400;
                          color: #777;
                          margin-bottom: 0;
                      }
  
                      .appointment-dialog-main-section-5-left-date {
                          display: flex;
                          align-items: center;
                          flex: 1;
  
                          .appointment-dialog-main-section-5-left-date-icon {
                              width: 16px;
                              height: 16px;
                              margin-right: 8px;
                          }
  
                          .appointment-dialog-main-section-5-left-datepicker {
                              .MuiInputBase-root {
                                  display: flex;
                                  flex-direction: row-reverse;
  
                                  &::before,
                                  &::after {
                                      content: "";
                                      display: none;
                                  }
  
                                  .MuiInputAdornment-positionEnd {
                                      margin-left: 0px;
                                  }
  
                                  .MuiInputAdornment-root {
                                      .MuiButtonBase-root {
                                          padding-left: 0px;
  
                                          &:hover {
                                              background-color: transparent;
                                          }
                                      }
                                  }
                              }
  
                              .MuiFormHelperText-root {
                                  display: none;
                              }
                          }
                      }
                  }
  
                  .appointment-dialog-main-section-5-right {
                      p {
                          font-size: 12px;
                          font-weight: 400;
                          color: #777;
                          margin-bottom: 0;
                      }
  
                      .MuiInput-underline:before {
                          border-bottom: 1px solid #d8d8d8 !important;
                      }
                  }
              }
  
              .disabled-section-class {
                  p {
                      color: #d8d8d8 !important;
                  }
  
                  input:disabled {
                      background-color: white;
                  }
              }
  
              .disabled-section-class-email-cc {
                  input {
                      color: #777;
                  }
              }
  
              .appointment-dialog-main-submit-button {
                  background-color: #f97f0f;
                  color: white;
                  font-size: 16px;
                  font-weight: 600;
                  text-align: center;
                  width: 100%;
                  border-radius: 4px;
                  padding: 10px 0;
                  outline: none;
                  border: none;
                  margin-top: 24px;
                  cursor: pointer;
  
                  // margin-bottom: 16px;
                  &:disabled {
                      background-color: #d8d8d8 !important;
                  }
              }
  
              // .appointment-dialog-main-submit-button:disabled {
              //   background-color: #d8d8d8 !important;
              // }
          }
      }
  
      .navigo-appoint-upload-dialog {
          border-radius: 12px;
          background-color: white;
          display: flex;
          flex-direction: column;
          padding: 12px;
          min-width: 300px;
  
          p {
              color: #333;
              font-size: 18px;
              font-weight: 800;
              text-align: center;
          }
  
          .navigo-appoint-upload-close {
              margin-left: auto;
              cursor: pointer;
          }
  
          .navigo-appoint-upload-dialog-button-1 {
              display: flex;
              align-items: center;
              justify-content: center;
              color: #f97f0f;
              background-color: #f7f7fc;
              border-radius: 5px;
              outline: none;
              border: none;
  
              margin-top: 16px;
              cursor: pointer;
  
              .navigo-appoint-upload-dialog-button-1-layout-1 {
                  background-color: #f7f7fc;
                  width: 100%;
                  height: 100%;
                  padding: 10px 0;
  
                  img {
                      width: 16px;
                      height: 16px;
                      margin-right: 6px;
                  }
              }
  
              .navigo-appoint-upload-dialog-button-1-layout-2 {
                  background-color: #f7f7fc;
                  width: 100%;
                  height: 100%;
                  padding: 10px 0;
  
                  span {
                      color: #51ac95;
                      font-weight: 600;
                      font-size: 16px;
                  }
              }
          }
  
          .navigo-appoint-upload-dialog-button-2 {
              background-color: #f97f0f;
              font-size: 16px;
              font-weight: 800;
              color: white;
              border-radius: 5px;
              outline: none;
              border: none;
              padding: 10px 0;
              margin: 20px 16px;
              cursor: pointer;
          }
      }
  
      .navigo-services-layout-container {
          .navigo-services-layout-1-container {
              padding: 20px;
  
              .navigo-services-section-1 {
                  margin-top: 16px;
                  cursor: pointer;
  
                  img {
                      width: 10px;
                      height: 10px;
                      margin-right: 12px;
                  }
  
                  span {
                      font-size: 14px;
                      font-weight: 700;
                      color: #333;
                  }
              }
  
              .navigo-services-section-2 {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-top: 32px;
  
                  .navigo-appoint-section-2-heading {
                      font-size: 48px;
                      font-weight: 700;
                      color: #333;
                  }
  
                  button {
                      background-color: #f97f0f;
                      border-radius: 4px;
                      outline: none;
                      border: none;
                      padding: 4px 8px;
                      font-size: 16px;
                      font-weight: 400;
                      color: white;
                      height: fit-content;
                      cursor: pointer;
                  }
              }
  
              .navigo-services-section-3 {
                  display: flex;
                  column-gap: 36px;
                  row-gap: 36px;
                  flex-wrap: wrap;
                  margin-top: 32px;
  
                  .service-items-container {
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      justify-content: flex-start;
                      min-width: 300px;
                      max-width: 300px;
                      padding: 20px 24px 32px 24px;
                      border: 1px solid #dee2e6;
                      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.06),
                          0px 1px 3px rgba(16, 24, 40, 0.1);
                      border-radius: 12px;
  
                      .service-items-section-1 {
                          width: 16px;
                          height: 16px;
                          margin-left: auto;
                          cursor: pointer;
                      }
  
                      .service-items-section-2 {
                          font-size: 20px;
                          font-weight: 800;
                          color: #333;
                      }
  
                      .service-items-section-3 {
                          font-size: 16px;
                          font-weight: 500;
                          color: #333;
                          text-align: center;
                          display: -webkit-box;
  
                          -webkit-line-clamp: 3;
                          -webkit-box-orient: vertical;
                          overflow: hidden;
                      }
  
                      .service-items-section-4 {
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          padding: 4px 16px;
                          border-radius: 25px;
                          background-color: #f7f7fc;
  
                          img {
                              width: 12px;
                              height: 12px;
                              margin-right: 8px;
                          }
  
                          p {
                              margin: 0;
                              font-size: 16px;
                              font-weight: 500;
                              color: #333;
                          }
                      }
                  }
              }
          }
  
          .navigo-services-layout-2-container {
              padding: 16px;
  
              .navigo-services-layout-2-section-1 {
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 12px;
  
                  input:disabled {
                      background-color: transparent !important;
                  }
  
                  input {
                      width: 600px;
                  }
  
                  .navigo-services-layout-2-section-1-name {
                      font-size: 48px;
                      font-weight: 700;
                      color: #333;
                      outline: none;
                      border: none;
                  }
  
                  .navigo-services-layout-2-section-1-right {
                      display: flex;
                      align-items: center;
                      column-gap: 16px;
  
                      #save {
                          background-color: #f97f0f;
                          color: #fff;
                      }
  
                      #save:disabled {
                          background-color: #d8d8d8 !important;
                      }
  
                      #cancel {
                          background-color: #ebebeb;
                          color: #333;
                      }
  
                      button {
                          outline: none;
                          border: none;
                          padding: 4px 24px;
                          font-size: 16px;
                          font-weight: 400;
                          border-radius: 4px;
                          cursor: pointer;
                      }
                  }
              }
  
              .navigo-services-layout-2-section-2 {
                  margin-bottom: 12px;
  
                  p {
                      font-size: 12px;
                      font-weight: 400;
                      color: #333;
                  }
  
                  .navigo-services-layout-2-section-2-description {
                      resize: none;
                      border: none;
                      border-bottom: 1px solid #d8d8d8;
                      width: 600px;
                      background-color: transparent;
  
                      &:focus {
                          outline: none;
                      }
                  }
              }
  
              .navigo-services-layout-2-section-instruction {
                  display: flex;
                  flex-direction: column;
  
                  .navigo-services-layout-2-section-instruction-heading {
                      font-size: 12px;
                      font-weight: 400;
                      color: #333;
                  }
  
                  .navigo-service-sd-wrapper-editor-wrapper-class {
                      .navigo_service_sd_editor_wrapper_editor_class {
                          min-height: 200px;
                          max-width: 600px;
                          border-bottom: 1px solid #d8d8d8;
                          margin-bottom: 8px;
                      }
  
                      .navigo-toolbar-class {
                          width: fit-content;
                      }
                  }
              }
  
              .navigo-services-layout-2-section-3 {
                  .navigo-services-layout-2-section-3-top {
                      p {
                          font-size: 12px;
                          font-weight: 400;
                          color: #333;
                      }
                  }
  
                  .navigo-services-layout-2-section-3-bottom {
                      display: flex;
                      flex-direction: column;
  
                      .navigo-services-timing-container {
                          display: grid;
                          grid-template-columns: 200px 200px 200px;
                          column-gap: 24px;
                          margin-top: 12px;
  
                          .navigo-services-timing-top {
                              display: flex;
                              align-items: center;
  
                              p {
                                  margin: 0 0 0 6px;
                                  color: #000;
                                  font-size: 14px;
                                  font-weight: 400;
                              }
                          }
  
                          .navigo-services-timing-mid-bottom {
                              p {
                                  font-size: 12px;
                                  font-weight: 400;
                                  color: #333;
                              }
                          }
                      }
                  }
              }
  
              .navigo-services-timing-mid-bottom-startTime-endTime {
                  min-width: 100%;
  
                  &::before {
                      border-bottom: 1px solid #d8d8d8;
                  }
  
                  &::after {
                      border-bottom: 1px solid #d8d8d8;
                  }
  
                  &:focus {
                      background-color: transparent;
                  }
  
                  &:focus-within {
                      background-color: transparent;
                  }
  
                  &::before :hover {
                      border-bottom: 1px solid #d8d8d8;
                  }
              }
  
              .navigo-services-layout-2-section-4-5 {
                  margin-bottom: 8px;
  
                  p {
                      font-size: 14px;
                      font-weight: 400;
                      margin-bottom: 0;
                  }
  
                  input {
                      outline: none;
                      border: none;
                      border-bottom: 1px solid #d8d8d8;
                      padding: 6px 0;
                      background-color: transparent;
                  }
              }
  
              .navigo-services-layout-2-section-calendar {
                  .navigo-services-layout-2-section-calendar-top {
                      display: flex;
  
                      p {
                          font-size: 14px;
                          font-weight: 400;
                          margin-bottom: 0;
                      }
  
                      button {
                          background-color: white;
                          border: none;
                          outline: none;
                          cursor: pointer;
                          color: #f97f0f;
                          font-size: 12px;
                          font-weight: 700;
                          margin-left: 16px;
                      }
                  }
  
                  .MuiInput-underline:before {
                      border-bottom: 1px solid #d8d8d8;
                  }
  
                  .MuiInput-underline::after {
                      border-bottom: 1px solid #d8d8d8;
                  }
              }
  
              .navigo-services-layout-2-section-6-8 {
                  display: flex;
                  flex-direction: column;
                  margin-bottom: 12px;
  
                  .navigo-services-layout-2-section-client-container {
                      display: flex;
                      flex-direction: column;
                  }
  
                  .navigo-services-layout-2-section-6-8-top-bottom {
                      display: grid;
                      grid-template-columns: 200px 200px;
                      column-gap: 24px;
                      align-items: center;
  
                      p {
                          font-size: 14px;
                          font-weight: 400;
                          margin-bottom: 0;
                      }
  
                      input {
                          outline: none;
                          border: none;
                          border-bottom: 1px solid #d8d8d8;
                          padding: 6px 0;
                      }
  
                      .navigo-services-layout-2-section-6-8-top-bottom-autocomplete {
                          .MuiInput-underline:before {
                              border-bottom: none;
                          }
  
                          .MuiInput-underline:after {
                              border-bottom: none;
                          }
                      }
                  }
  
                  .navigo-services-layout-2-section-6-8-top-bottom-client {
                      display: grid;
                      grid-template-columns: 200px 100px 60px;
                      align-items: center;
                      column-gap: 24px;
  
                      p {
                          font-size: 14px;
                          font-weight: 400;
                          margin-bottom: 0;
                      }
  
                      input {
                          outline: none;
                          border: none;
                          border-bottom: 1px solid #d8d8d8;
                          padding: 6px 0;
                      }
  
                      .navigo-services-layout-2-section-6-8-top-bottom-client-no-border {
                          input {
                              border-bottom: none !important;
                          }
                      }
  
                      .navigo-services-layout-2-section-6-8-top-bottom-autocomplete {
                          .MuiInput-underline:before {
                              border-bottom: none;
                          }
  
                          .MuiInput-underline:after {
                              border-bottom: none;
                          }
                      }
  
                      .navigo-service-client-close-button {
                          border-radius: 50%;
                          margin-left: 16px;
                      }
  
                      .navigo-services-layout-2-section-6-8-top-bottom-div {
                          display: flex;
                          align-items: center;
  
                          button {
                              margin-left: 12px;
                              width: fit-content;
                          }
                      }
  
                      .navigo-dashboard-client-edit-icon {
                          width: 24px;
                          margin-left: 16px;
                      }
                  }
  
                  .navigo-services-layout-2-section-6-8-button {
                      width: fit-content;
                      background-color: transparent;
                      color: #f97f0f;
                      outline: none;
                      border: none;
                      margin: 16px 0;
                      cursor: pointer;
                  }
              }
  
              .navigo-services-layout-2-section-9 {
                  display: flex;
                  flex-direction: column;
  
                  .navigo-services-layout-2-section-9-heading {
                      font-size: 12px;
                      font-weight: 700;
                      color: #333;
                      margin-bottom: 12px;
                  }
  
                  .navigo-services-layout-2-section-9-item {
                      display: flex;
                      align-items: center;
                      margin-bottom: 12px;
  
                      p {
                          font-size: 14px;
                          font-weight: 400;
                          color: #000;
                          margin: 0 0 0 6px;
                      }
                  }
  
                  .navigo-services-layout-2-section-9-top {
                      display: flex;
                      align-items: center;
  
                      .navigo-services-layout-2-section-9-right {
                          input {
                              outline: none;
                              border: none;
                              border-bottom: 1px solid #d8d8d8;
                              margin-left: 12px;
                              width: 60px;
                              background-color: transparent;
                          }
                      }
  
                      .navigo-services-layout-2-section-9-top-group {
                          display: flex;
                          flex-direction: column;
                      }
                  }
              }
  
              .navigo-services-layout-2-section-10 {
                  display: flex;
                  flex-direction: column;
  
                  p {
                      font-size: 12px;
                      font-weight: 400;
                      color: #333;
                  }
  
                  .navigo-services-layout-2-section-10-layout-1 {
                      margin-bottom: 12px;
  
                      button {
                          outline: none;
                          border: none;
                          background-color: #d8d8d8;
                          border-radius: 8px;
                          padding: 6px 16px;
                          font-size: 12px;
                          font-weight: 400;
                          color: #000;
                      }
                  }
  
                  .navigo-services-layout-2-section-10-layout-2 {
                      border-radius: 8px;
                      height: 200px;
                      width: 200px;
                      overflow: hidden;
                      margin-bottom: 12px;
  
                      img {
                          width: 100%;
                          height: 100%;
                          object-fit: cover;
                      }
                  }
              }
  
              .navigo-services-booking-notice-module {
                  display: grid;
                  grid-template-columns: 300px 300px;
              }
  
              .navigo-service-type-package {
                  border-bottom: none !important;
                  column-gap: 20px;
  
                  input {
                      border-bottom: none !important;
                  }
              }
  
              .navigo-services-booking-price-module {
                  display: grid;
                  grid-template-columns: 200px 200px;
                  column-gap: 24px;
  
                  input {
                      width: 100%;
                  }
              }
          }
  
          .navigo-services-global-skeleton-loader {
              min-height: 600px !important;
              min-width: 600px !important;
          }
      }
  
      .autocomplete-render-options-list {
          font-size: 18px;
          color: #333;
          font-weight: 500;
      }
  
      .fetching-time-slot-container {
          display: flex;
          align-items: center;
          border-bottom: 1px solid #d8d8d8;
          min-width: 100%;
  
          p {
              font-size: 14px;
              padding: 6px 0;
              font-weight: 400;
              color: #464646;
              margin-right: 6px;
          }
  
          .animated-dots-parent {
              display: flex;
              align-items: center;
              justify-content: space-around;
              max-width: 30px;
              min-width: 30px;
  
              .animated-dots {
                  width: 4px;
                  height: 4px;
                  border-radius: 50%;
                  background-color: #d8d8d8;
                  animation-name: scaling-animate;
                  animation-duration: 1s;
                  animation-direction: alternate;
                  animation-iteration-count: infinite;
                  animation-timing-function: ease-in-out;
              }
  
              .animated-delay-0 {
                  animation-delay: 0ms;
              }
  
              .animated-delay-1 {
                  animation-delay: 300ms;
              }
  
              .animated-delay-2 {
                  animation-delay: 600ms;
              }
          }
      }
  
      @keyframes scaling-animate {
          0% {
              transform: scale(100%, 100%);
          }
  
          100% {
              transform: scale(200%, 200%);
          }
      }
  
      .navigo-dashboard-select-css>.MuiSelect-select:focus {
          background-color: white !important;
      }
  
      .navigo-appointment-client-code-select {
          border-bottom: none;
          padding-left: 4px;
  
          .MuiSelect-root {
              color: #464646 !important;
          }
  
          &::before {
              border-bottom: none !important;
          }
  
          &::after {
              border-bottom: none !important;
          }
  
          .MuiSvgIcon-root {
              transform: translateY(-5px);
              top: 50%;
              background-image: url("../../../../../assets/icons/funnel.svg");
              background-repeat: no-repeat;
              fill: none;
              color: white;
          }
  
          .MuiSelect-select.MuiSelect-select {
              padding-right: 30px;
          }
      }
  
  
      .general-cursor-pointer {
          cursor: pointer;
      }
  
      .no-address-found-autocomplete-node {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          padding: 60px;
  
          p {
              font-size: 12px;
              font-weight: 600;
              color: #000;
              margin-bottom: 6px;
          }
  
          a {
              text-decoration: none;
              color: #f97f0f;
              font-size: 12px;
              font-weight: 600;
          }
      }
  
      .navigo-call-note {
          background: #fff;
          height: 400px;
          width: 700px;
          overflow: scroll;
          border-radius: 4px;
          padding: 20px;
      }
  
      .general-navigo-status-select-class {
          border-bottom: 1px solid #d8d8d8;
          font-weight: 600;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          padding-left: 5px;
          font-family: Montserrat;
      }
  
      .general-navigo-anchor-tag {
          margin-bottom: 12px;
  
          a {
              text-decoration: none;
              outline: none;
              border: none;
              background-color: white;
              color: #f97f0f;
              font-size: 14px;
              font-weight: 600;
          }
      }
  
      .navigo-appointment-break-word-class {
          word-break: break-word !important;
      }
  
      .navigo-dashboard-cursor-pointer-class {
          cursor: pointer;
      }
  
      .label-radio-btn {
          position: relative;
      }
  
      .navigo-appoint-section-5-grid-container-section-common-filter-small-popup {
          position: absolute;
          bottom: -90px;
          right: 0%;
          transform: translateX(20%);
          z-index: 50;
          background-color: white;
          width: 150px;
          padding: 20px;
          border-radius: 8px;
          border: 1px solid #d8d8d8;
          display: block;
          align-items: flex-end;
  
          .appointment-dialog-client-code-filter-class {
              flex: 1;
          }
  
          // height: 100px;
          .navigo-appointment-date-range-filter {
              flex: 1;
          }
      }
  
      .service-type-filter {
          position: absolute;
          bottom: -300px;
          right: 0%;
          transform: translateX(20%);
          z-index: 50;
          background-color: white;
          width: 300px;
          padding: 20px;
          border-radius: 8px;
          border: 1px solid #d8d8d8;
          display: block;
          align-items: flex-end;
  
          .appointment-dialog-client-code-filter-class {
              flex: 1;
          }
      }
      .can-be-booked-for-filter {
          position: absolute;
          bottom: -181px;
          right: -34%;
          transform: translateX(20%);
          z-index: 50;
          background-color: white;
          width: 182px;
          padding: 20px;
          border-radius: 8px;
          border: 1px solid #d8d8d8;
          display: block;
          align-items: flex-end;
  
          .appointment-dialog-client-code-filter-class {
              flex: 1;
          }
      }
      .mp-service-dashboard-status-filter {
          position: absolute;
          bottom: -144px;
          right: 14%;
          transform: translateX(20%);
          z-index: 50;
          background-color: white;
          width: 160px;
          padding: 20px;
          border-radius: 8px;
          border: 1px solid #d8d8d8;
          display: block;
          align-items: flex-end;
  
          .appointment-dialog-client-code-filter-class {
              flex: 1;
          }
      }
      .mp-service-dashboard-acc-type-filter {
        position: absolute;
        bottom: -219px;
        right: -47%;
        transform: translateX(20%);
        z-index: 50;
        background-color: white;
        width: 160px;
        padding: 20px;
        border-radius: 8px;
        border: 1px solid #d8d8d8;
        display: block;
        align-items: flex-end;

        .appointment-dialog-client-code-filter-class {
            flex: 1;
        }
    }
  
      .clear-navigo-booked-for {
          border-radius: 4px;
          width: 100%;
          color: white;
          background-color: #ef7c01;
      }
  
      .general-orange-save-button {
          background-color: #f97f0f;
          color: white;
          font-size: 16px;
          font-weight: 600;
          text-align: center;
          width: 100%;
          border-radius: 4px;
          padding: 10px 0;
          outline: none;
          border: none;
          margin-top: 24px;
          cursor: pointer;
  
          // margin-bottom: 16px;
          &:disabled {
              background-color: #d8d8d8 !important;
          }
      }
  
      .navigo-change-package-container {
          display: flex;
          flex-direction: column;
          row-gap: 12px;
          padding: 12px;
          border-radius: 4px;
          background-color: white;
          min-width: 400px;
  
          .navigo-change-package-container-top {
              display: flex;
              align-items: center;
              justify-content: space-between;
  
              p {
                  font-weight: 600;
              }
          }
  
          .navigo-change-package-container-mid-bottom {
              display: flex;
              flex-direction: column;
  
              p {
                  font-size: 14px;
                  font-weight: 400;
                  margin-bottom: 0;
                  color: #777777;
              }
  
              input {
                  outline: none;
                  border: none;
                  // border-bottom: 1px solid #d8d8d8;
                  padding: 6px 0;
                  background-color: transparent;
                  color: black;
              }
  
              .MuiInput-underline:before {
                  border-bottom: 1px solid #d8d8d8;
              }
  
              .MuiInput-underline::after {
                  border-bottom: 1px solid #d8d8d8;
              }
  
              .modal-overlay {
                  position: fixed;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  background-color: rgba(0, 0, 0, 0.5);
                  display: flex;
                  justify-content: center;
                  align-items: center;
              }
  
              .modal {
                  background-color: #fff;
                  padding: 20px;
                  border-radius: 5px;
                  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                  height: 35%;
                  width: 80%;
                  max-width: 800px;
                  position: relative;
              }
  
              .modal h2 {
                  margin-top: 0;
                  margin-bottom: 10px;
              }
  
              .modal-content {
                  max-height: 300px;
                  overflow-y: auto;
              }
  
              .modal-close-button {
                  position: absolute;
                  top: 10px;
                  right: -75px;
                  background: none;
                  border: none;
                  cursor: pointer;
                  width: 53px;
                  height: 53px;
                  border-radius: 70%;
                  background-color: #fff;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: #333;
                  font-weight: bold;
                  font-size: 17px;
              }
  
          }
      }
  
      .custom-modal-content {
          background-color: white;
          padding: 20px;
          width: 650px;
          margin: auto;
          top: 20%;
          left: 20%;
          transform: translate(-50%, -20%);
          box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          text-align: left;
          position: relative;
      }
  
      .scrollable-modal-full-height {
          max-height: 95vh;
          overflow-y: scroll;
          scrollbar-color: #00C9D3 #d0d0d0;
          scroll-behavior: smooth;
          scrollbar-width: thin;
      }
  
      .modal-title {
          font-size: 1.5rem;
          margin-bottom: 15px;
          font-weight: bold;
      }
  
      .full-width-button {
          margin-top: 20px;
          width: 100%;
          height: 52px;
          background-color: #e0e0e0;
          color: #fff;
          font-size: 16px;
          border-radius: 5px;
      }
  
      .MuiCheckbox-colorSecondary.Mui-checked {
          color: rgba(39, 174, 96, 1) !important;
      }
  
      .custom-select {
          position: relative;
          display: inline-block;
          width: 200px;
      }
  
      .custom-select select {
          width: 100%;
          padding: 10px 30px 10px 10px;
          font-size: 16px;
          border-radius: 5px;
          border: 1px solid #ccc;
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          background: none;
      }
  
      .custom-select::after {
          content: '';
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          width: 20px;
          height: 20px;
          background: url('/path-to-your-image/arrow-down.svg') no-repeat center center;
          background-size: contain;
          pointer-events: none;
      }
  
      .document-box-div {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          border: 1px solid #CED4DA;
          border-radius: 4px;
          padding-left: 15px;
          padding-right: 15px;
          padding-top: 10px;
          padding-bottom: 10px;
          cursor: pointer;
          margin-bottom: 10px;
      }
  
      .document-box-div-error {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          border: 1px solid #E44437;
          border-radius: 4px;
          padding-left: 15px;
          padding-right: 15px;
          padding-top: 10px;
          padding-bottom: 10px;
          cursor: pointer;
          margin-bottom: 10px;
      }
  
      .custom-date-picker-default-icon-hidden input[type='date']::-webkit-calendar-picker-indicator {
          display: none !important;
      }
      .navigo-module-buttons-right-alignment{
        text-align: right;
        }
    .navigo-module-button-save-enabled{
            cursor: pointer;
            margin-left: 10px;
            min-width: 100px;
            text-transform: none;
            font-size: medium;
            border-radius: 5px;
            color: #FFFFFF;
            background-color: #F77F11;
            // box-shadow: 0px 2px 3px rgba(46, 46, 46, 0.2);
          }
          .mp-phone-container{
            display: flex;
            align-items: center;
          }
          .mp-select-field{
            width: 100%;
            font-size: larger;
            font-family: Montserrat;
          }
          .autocomplete-text-field-class {
            .MuiInputBase-root {
              .MuiChip-root {
                background-color: #747ef3;
                color: white;
                .MuiSvgIcon-root {
                  color: white;
                }
              }
            }
          }

    .status-label {
        border-radius: 60px;
        padding: 2px 10px;
        width: fit-content;
        font-size: 14px;
        font-weight: 700;
        display: inline-flex;
        align-items: center;
    }
    
    .pending-class {
        background-color: #fff9e3;
        color: #ffbd2d;
    }
    
    .progress-class {
        background-color: rgba(239, 124, 1, 0.1);
        color: #ef7c01;
    }
    
    .closed-class {
        background-color: rgba(48, 197, 63, 0.1);
        color: rgba(48, 197, 63, 1);
    }
    
    .invalid-class {
        background: rgba(0, 0, 0, 0.1);
        color: black;
    }
    
    .navigo-appoint-non-select-status-inactive {
        background: #FBDFD9;
        color: #E44437;
    }
    
    .navigo-appoint-non-select-status-active {
        background-color: #caecde;
        color: #0b5033;
    }
    
    /* Ensuring icons inside the status label follow the text color */
    .status-label .MuiSvgIcon-root {
        color: inherit;
    }

        
}